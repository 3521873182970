var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"inline-flex select-none items-center",class:{
    'cursor-pointer': !_vm.disabled,
  },attrs:{"data-cy":_vm.dataCy}},[_c('input',_vm._b({staticClass:"sr-only",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.$attrs.value},on:{"change":function($event){return _vm.$emit('input', $event.target.checked)}}},'input',_vm.$attrs,false)),_c('span',{staticClass:"mr-3 flex h-6 w-12 items-center rounded-32 p-0.5",class:{
      'bg-s1-gray-50': !_vm.$attrs.value,
      'bg-s1-blue-100': _vm.$attrs.value,
      'opacity-50': _vm.disabled,
    }},[_c('span',{staticClass:"shadow-toggle-button h-5 w-5 rounded-3xl bg-s1-main-100 duration-200 transform",class:{
        'translate-x-6': _vm.$attrs.value,
      },attrs:{"data-cy":"switch-button"}})]),_c('span',{staticClass:"flex items-center text-sm font-medium"},[_c('div',{staticClass:"ml-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('input', !_vm.$attrs.value)}}},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }