import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/orders', { params })
  },
  show(number) {
    return apiClient.get(`api/orders/${number}`)
  },
  update(id, params) {
    return apiClient.put(`api/orders/${id}`, params)
  },
  delete(params) {
    return apiClient.delete(`api/orders/${params['number']}`, { data: params })
  },
  attachments: {
    create(formData) {
      return apiClient.post(
        `api/orders/${formData.get('order_id')}/order_attachments`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
    delete(id) {
      return apiClient.delete(`api/order_attachments/${id}`)
    },
  },
  cancel: {
    update(id, params) {
      return apiClient.put(`api/orders/${id}/cancel`, params)
    },
  },
  confirmation: {
    update({ order_id }) {
      return apiClient.put(`api/orders/${order_id}/confirmation`)
    },
  },
  versions: {
    index(id, params) {
      return apiClient.get(`api/orders/${id}/versions`, { params })
    },
  },
  current: {
    update(params) {
      return apiClient.put('api/orders/current', params)
    },
  },
  order_templates: {
    create(id, params) {
      return apiClient.post(`api/orders/${id}/order_templates`, params)
    },
  },
  process: {
    update(order_id, params) {
      return apiClient.put(`api/orders/${order_id}/process`, params)
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/orders.pdf', {
        params,
        responseType: 'blob',
      })
    },
    show(order) {
      return apiClient.get(`api/orders/${order.number}.pdf`, {
        responseType: 'blob',
      })
    },
  },
  shorts: {
    index(params) {
      return apiClient.get(`api/orders/shorts`, { params })
    },
  },
  totals: {
    index(params) {
      return apiClient.get(`api/orders/totals`, { params })
    },
  },
}
