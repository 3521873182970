import { ref, onMounted, onBeforeUnmount } from 'vue'
import { calculateScreenSize } from '@/services/helpers'

const useDetectScreenSize = () => {
  const isXsMobileView = ref(false)
  const isMobileView = ref(false)
  const isSmView = ref(false)
  const isTabletView = ref(false)
  const isDesktopView = ref(false)
  const isDesktopExtraView = ref(false)

  const handleWindowResize = () => {
    const result = calculateScreenSize()

    isXsMobileView.value = result.isXsMobileView
    isMobileView.value = result.isMobileView
    isSmView.value = result.isSmView
    isTabletView.value = result.isTabletView
    isDesktopView.value = result.isDesktopView
    isDesktopExtraView.value = result.isDesktopExtraView
  }

  onMounted(() => {
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleWindowResize)
  })

  return {
    isXsMobileView,
    isMobileView,
    isSmView,
    isTabletView,
    isDesktopView,
    isDesktopExtraView,
  }
}

export default useDetectScreenSize
