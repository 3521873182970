import { getCurrentInstance } from 'vue'

const useAbility = () => {
  const instance = getCurrentInstance()
  const ability = instance.proxy.$ability

  const can = (...args) => ability.can(...args)

  return { can }
}

export default useAbility
