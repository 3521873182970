import itemExtractReplacementGroupsApi from '@/services/api/item-extract-replacement-groups'
import { uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  list: [],
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_EXTRACTED_GROUPS_DATA(state, { replacement_groups, ...paginationMeta }) {
    state.list = uniqBy([...state.list, ...replacement_groups], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
  },
  RESET_LOADED_EXTRACTED_GROUPS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  filterExtractedReplacementGroups({ commit }) {
    commit('RESET_LOADED_EXTRACTED_GROUPS')
  },
  async getExtractedReplacementGroups(
    { commit, state },
    { infiniteLoaderContext, id, groupType, forceExtract = false }
  ) {
    const { current_page, q } = state

    try {
      const response = await itemExtractReplacementGroupsApi[groupType].index(
        id,
        {
          page: current_page + 1,
          q,
          force_extract: forceExtract,
        }
      )

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_EXTRACTED_GROUPS_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
}
