import accountsApi from '@/services/api/accounts'
import companiesApi from '@/services/api/companies'
import costCentersApi from '@/services/api/cost-centers'
import session from '@/services/api/session'
import userPreferences from '@/services/api/user-preferences'
import profile from '@/services/api/profile'
import TokenStorage from '@/services/token-storage'

export const namespaced = true

const defaultCart = {
  total_items_count: 0,
  line_items: [],
  number: null,
  spree_user_id: null,
  orders: [],
  rg_minmax_variants: {
    max_ids: [],
    min_ids: [],
  },
  isLoaded: false,
}

export const state = {
  currentUser: {
    roles: [],
    profile_roles: [],
    preference: {
      users_expanded: true,
      dark_mode: false,
    },
    currentCart: defaultCart,
    company: {
      loading: false,
      value: null,
      options: [],
    },
    cost_center: {
      loading: false,
      value: null,
      options: [],
    },
    account: {
      loading: false,
      value: null,
      options: [],
    },
    notifications: {
      items_updated_statistic: null,
    },
  },
  settings: {
    navbar: {
      menu: null,
      isCollapsed: true,
    },
  },
}

export const mutations = {
  ADD_NOTIFICATION(state, { name, data }) {
    state.currentUser.notifications[name] = data
  },
  CLEAR_NOTIFICATION(state, { name }) {
    state.currentUser.notifications[name] = null
  },
  ADD_CURRENT_CART_TOTAL_ITEMS_COUNT(state, value) {
    state.currentUser.currentCart.total_items_count += value
  },
  CLEAR_CURRENT_USER_CART(state) {
    state.currentUser.currentCart = defaultCart
  },
  SET_AVAILABLE_ACCOUNTS(state, data) {
    state.currentUser.account.options = data.accounts
  },
  SET_AVAILABLE_COMPANIES(state, data) {
    state.currentUser.company.options = data.companies.filter(
      (item) => !item.discarded_at
    )
  },
  SET_AVAILABLE_COST_CENTERS(state, data) {
    state.currentUser.cost_center.options = data.cost_centers.filter(
      (item) => !item.discarded_at
    )
  },
  SET_ACCOUNT_LOADING(state, value) {
    state.currentUser.account.loading = value
  },
  SET_COMPANIES_LOADING(state, value) {
    state.currentUser.company.loading = value
  },
  SET_COST_CENTERS_LOADING(state, value) {
    state.currentUser.cost_center.loading = value
  },
  SET_CURRENT_USER_CART(state, data) {
    state.currentUser.currentCart = { ...defaultCart, ...data, isLoaded: true }
  },
  SET_CURRENT_USER_CART_ITEM(state, { item }) {
    state.currentUser.currentCart = {
      ...state.currentUser.currentCart,
      orders: state.currentUser.currentCart.orders.map((order) => {
        order.line_items = order.line_items.map((oldItem) => {
          if (oldItem.variant_id !== item.variant_id) return oldItem

          return { ...oldItem, ...item }
        })

        return order
      }),
    }
  },
  SET_CURRENT_USER_CART_NAME(state, cartName) {
    state.currentUser.currentCart.cart_name = cartName
  },
  SET_CURRENT_USER_TOKENS(_state, { tokens }) {
    TokenStorage.setAccessToken(tokens.access)
    TokenStorage.setRefreshToken(tokens.refresh)
  },
  SET_CURRENT_USER_DATA(state, { user }) {
    const { account, ...userProperties } = user
    state.currentUser = { ...state.currentUser, ...userProperties }
    state.currentUser.account.value = account
    state.currentUser.company.value = user.preference.company
    state.currentUser.cost_center.value = user.preference.cost_center
    state.currentUser.preference.dark_mode = user.preference.dark_mode
    localStorage.setItem('currentUser', JSON.stringify(user))
  },
  SET_CURRENT_USER_ACCOUNT(state, account) {
    state.currentUser.account.value = account
    const userString = localStorage.getItem('currentUser')
    const currentUser = JSON.parse(userString)
    localStorage.setItem(
      'currentUser',
      JSON.stringify({ ...currentUser, account })
    )
  },
  SET_CURRENT_COST_CENTER_ABILITY(state, value) {
    const { value: costCenterData } = state.currentUser.cost_center

    if (costCenterData) {
      costCenterData.only_owner_can_manage_carts = value
    }
  },
  SET_NAVBAR_SETTING(state, value) {
    state.settings.navbar = { ...state.settings.navbar, ...value }
  },
  SET_NAVBAR_MENU_ITEMS(state, value) {
    state.settings.navbar.menu = state.settings.navbar.menu.map((item) => {
      if (item.title !== value.title) {
        return item
      } else {
        return value
      }
    })
  },
  REMOVE_CURRENT_USER_TOKENS() {
    TokenStorage.removeAccessToken()
    TokenStorage.removeRefreshToken()
    location.reload()
  },
  REFRESH_CURRENT_USER_TOKENS(state, { tokens }) {
    state.currentUser = {
      ...state.currentUser,
      ...tokens,
    }
  },
  UPDATE_CURRENT_CART_ITEMS(state, data) {
    state.currentUser.currentCart.total_items_count = data.total_items_count
    state.currentUser.currentCart.line_items = data.line_items
  },
  UPDATE_CURRENT_CART_OPTIMIZATION_TYPE(state, data) {
    state.currentUser.currentCart.optimization_type = data.optimization_type
    state.currentUser.currentCart.rg_minmax_variants = data.rg_minmax_variants
  },
  TOGGLE_DARK_MODE(state) {
    state.currentUser.preference.dark_mode =
      !state.currentUser.preference.dark_mode
  },
  UPDATE_CURRENT_USER_COMPANY(state, value) {
    state.currentUser.company.value = value
  },
  UPDATE_USER_PREFERENCE(state, { user }) {
    const { account, ...userProperties } = user
    state.currentUser = { ...state.currentUser, ...userProperties }
    state.currentUser.company.value = user.preference.company
    state.currentUser.cost_center.value = user.preference.cost_center
    state.currentUser.account.value = account
    localStorage.setItem('currentUser', JSON.stringify(user))
  },
}

export const actions = {
  async createSession({ commit }, params) {
    const response = await session.create(params)

    commit('SET_CURRENT_USER_TOKENS', response.data)
    commit('SET_CURRENT_USER_DATA', response.data)

    return response.data.user
  },
  async getAvailableAccounts({ commit }) {
    commit('SET_ACCOUNT_LOADING', true)
    const response = await accountsApi.shorts.index()

    commit('SET_ACCOUNT_LOADING', false)
    commit('SET_AVAILABLE_ACCOUNTS', response.data)
  },
  async getAvailableCompanies({ commit }) {
    commit('SET_COMPANIES_LOADING', true)
    const response = await companiesApi.index({ all: true })

    commit('SET_COMPANIES_LOADING', false)
    commit('SET_AVAILABLE_COMPANIES', response.data)

    return response.data
  },
  async getAvailableCostCenters({ commit }, { company_id }) {
    commit('SET_COST_CENTERS_LOADING', true)
    const response = await costCentersApi.index({ all: true, company_id })

    commit('SET_COST_CENTERS_LOADING', false)
    commit('SET_AVAILABLE_COST_CENTERS', response.data)

    return response
  },
  async getSmtpSetting() {
    const response = await profile.smtp_setting.show()

    return response
  },
  addCurrentCartTotalItemsCount({ commit }, value) {
    commit('ADD_CURRENT_CART_TOTAL_ITEMS_COUNT', value)
  },
  async deleteSession({ commit }) {
    await session.delete()

    commit('REMOVE_CURRENT_USER_TOKENS')
  },
  async sendTestEmail() {
    await profile.smtp_setting.test_email.create()
  },
  async updateSession({ commit }) {
    const { data } = await session.update()

    commit('REFRESH_CURRENT_USER_TOKENS', data)

    return data
  },
  setCurrentUserAccount({ commit }, account) {
    commit('SET_CURRENT_USER_ACCOUNT', account)
  },
  setCurrentUser({ commit }, user) {
    commit('SET_CURRENT_USER_DATA', { user })
  },
  setCurrentCart({ commit }, cart) {
    commit('SET_CURRENT_USER_CART', cart)
  },
  clearCurrentCart({ commit }) {
    commit('CLEAR_CURRENT_USER_CART')
  },
  async updatePreferences({ commit }, params) {
    const { data } = await userPreferences.update(params)

    commit('SET_CURRENT_USER_DATA', { user: data.user })
  },
  updateCurrentUserCompany({ commit }, value) {
    commit('UPDATE_CURRENT_USER_COMPANY', value)
  },
  async updateProfile({ commit }, params) {
    const { data } = await profile.update({
      ...params.information,
      ...params.security,
      ...params.settings,
    })

    commit('SET_CURRENT_USER_DATA', { user: data })
  },
  async updateAvatar({ commit }, params) {
    const { data } = await profile.avatar.update(params)

    commit('SET_CURRENT_USER_DATA', { user: data })
  },
  updateNavbarSettings({ commit }, value) {
    commit('SET_NAVBAR_SETTING', value)
  },
  updateNavbarMenuItems({ commit }, value) {
    commit('SET_NAVBAR_MENU_ITEMS', value)
  },
  async updateSmtpSetting(_ctx, params) {
    await profile.smtp_setting.update(params)
  },
  toggleDarkMode({ commit }) {
    commit('TOGGLE_DARK_MODE')
  },
  clearNotification({ commit }, { name }) {
    commit('CLEAR_NOTIFICATION', { name })
  },
}

export const getters = {
  isAdmin: (state) => {
    return state.currentUser.roles.includes('admin')
  },
  myRoles: (state) => {
    return state.currentUser.roles
  },
  joinUserRoles: (_, getters) => {
    return getters.profileRolesFilter.reduce((userRoles, role) => {
      if (!userRoles.includes(role.name)) userRoles.push(role.name)
      return userRoles
    }, [])
  },
  profileRolesFilter: (state, getters) => {
    if (
      !state.currentUser.company.value &&
      !state.currentUser.cost_center.value
    ) {
      return state.currentUser.profile_roles
    }

    return [
      ...getters.accountOwnerRoleFilter,
      ...getters.costCenterRolesFilter,
      ...getters.companyRolesFilter,
      ...getters.supportRoleFilter,
    ]
  },
  costCenterRolesFilter: (state) => {
    return state.currentUser.profile_roles.filter((role) => {
      if (
        state.currentUser.cost_center.value &&
        role.resource_id === state.currentUser.cost_center.value.id &&
        role.resource_type === 'CostCenter'
      ) {
        return role
      }
    })
  },
  companyRolesFilter: (state) => {
    return state.currentUser.profile_roles.filter((role) => {
      if (
        state.currentUser.company.value &&
        role.resource_id === state.currentUser.company.value.id &&
        role.resource_type === 'Company'
      ) {
        return role
      }
    })
  },
  accountOwnerRoleFilter: (state) => {
    return state.currentUser.profile_roles.filter((role) => {
      if (
        role.resource_id === null &&
        role.resource_type === null &&
        state.currentUser.roles.includes('account_owner')
      ) {
        return role
      }
    })
  },
  supportRoleFilter: (state) => {
    return state.currentUser.profile_roles.filter((role) => {
      if (state.currentUser.roles.includes('support')) {
        return role
      }
    })
  },
  canManageCart: (state) => {
    const { currentUser } = state
    const { value: currentCostCenter } = state.currentUser.cost_center

    if (!currentUser.currentCart.number) return true
    if (currentUser.roles.includes('account_owner')) return true
    if (currentUser.currentCart.spree_user_id === currentUser.id) return true

    if (currentCostCenter && currentCostCenter.only_owner_can_manage_carts)
      return false

    return true
  },
  currentCompanyName: (state) => {
    const {
      currentUser: {
        company: { value },
      },
    } = state

    return value && value.name
  },
  currentCostCenterName: (state) => {
    const {
      currentUser: {
        cost_center: { value },
      },
    } = state

    return value && value.name
  },
  cartItemsCount: (state) => {
    const { total_items_count } = state.currentUser.currentCart

    return total_items_count < 100 ? total_items_count : '99+'
  },
  theme: (state) => {
    const token = TokenStorage.getAccessToken()

    return state.currentUser.preference.dark_mode && token ? 'dark' : 'light'
  },
  isDarkMode: (_, getters) => {
    return getters.theme === 'dark'
  },
  currentLocale: (state) => {
    return state.currentUser.preference.locale || 'en'
  },
  isDemoPlan: (state) => {
    return state.currentUser.account?.value?.is_demo
  },
  demoFeaturesEnabled: (_, getters) => {
    return getters.featureEnabled('demo')
  },
  featureEnabled: (state) => (key) => {
    return (
      state.currentUser.account?.value?.enabled_features?.includes(key) || false
    )
  },
}
