import tenantsApi from '@/services/api/tenants'

export const namespaced = true

export const state = {
  users: [],
}

export const mutations = {
  SET_USERS(state, users) {
    state.users = users
  },
}

export const actions = {
  async getTenantUsers({ commit }, { tenant_id, ...params }) {
    const response = await tenantsApi.users.index(tenant_id, params)

    commit('SET_USERS', response.data.users)
  },
  async updateTenantFeature({ commit }, { key, enabled }) {
    const response = await tenantsApi.features.update(key, { enabled })

    commit('currentUser/SET_CURRENT_USER_DATA', response.data, {
      root: true,
    })
  },
}
