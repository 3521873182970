import apiClient from '@/services/api'

export default {
  users: {
    index(tenant_id, params) {
      return apiClient.get(`api/tenants/${tenant_id}/users`, { params })
    },
  },
  features: {
    update(key, params) {
      return apiClient.put(`api/tenants/features/${key}`, params)
    },
  },
}
