import apiClient from '@/services/api'

export default {
  assign: {
    create(id, params) {
      return apiClient.post(`api/items/${id}/replacement_groups/assign`, params)
    },
  },
  bls: {
    index(id, params) {
      return apiClient.get(`api/items/${id}/replacement_groups/extract_bls`, {
        params,
      })
    },
  },
  custom: {
    index(id, params) {
      return apiClient.get(`api/items/${id}/replacement_groups/extract`, {
        params,
      })
    },
  },
}
