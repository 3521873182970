import Vue from 'vue'
import Vuex from 'vuex'
import * as accounts from '@/store/modules/accounts'
import * as additives from '@/store/modules/additives'
import * as allergens from '@/store/modules/allergens'
import * as baseUnitTypes from '@/store/modules/base-unit-types'
import * as cart from '@/store/modules/cart'
import * as cartTemplates from '@/store/modules/cart-templates'
import * as companies from '@/store/modules/companies'
import * as currentUser from '@/store/modules/current-user'
import * as dashboard from '@/store/modules/dashboard'
import * as deliveries from '@/store/modules/deliveries'
import * as delivery from '@/store/modules/delivery'
import * as dictionaries from '@/store/modules/dictionaries'
import * as expiringPrices from '@/store/modules/expiring-prices'
import * as itemsImports from '@/store/modules/items-imports'
import * as importMappings from '@/store/modules/import-mappings'
import * as integrations from '@/store/modules/integrations'
import * as invitations from '@/store/modules/invitations'
import * as item from '@/store/modules/item'
import * as itemExtractReplacementGroups from '@/store/modules/item-extract-replacement-groups'
import * as itemReplacements from '@/store/modules/item-replacements'
import * as itemStatistics from '@/store/modules/item-statistics'
import * as itemPriceHistory from '@/store/modules/item-price-history'
import * as items from '@/store/modules/items'
import * as lookups from '@/store/modules/lookups'
import * as masterOrder from '@/store/modules/master-order'
import * as masterOrders from '@/store/modules/master-orders'
import * as openPayments from '@/store/modules/open-payments'
import * as order from '@/store/modules/order'
import * as orderTemplate from '@/store/modules/order-template'
import * as packagingTypes from '@/store/modules/packaging-types'
import * as pages from '@/store/modules/pages'
import * as procurements from '@/store/modules/procurements'
import * as productGroups from '@/store/modules/product-groups'
import * as replacementGroups from '@/store/modules/replacement-groups'
import * as suppliers from '@/store/modules/suppliers'
import * as supplierTenants from '@/store/modules/supplier-tenants'
import * as settings from '@/store/modules/settings'
import * as taxRates from '@/store/modules/tax-rates'
import * as tenants from '@/store/modules/tenants'
import * as units from '@/store/modules/units'
import * as users from '@/store/modules/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    accounts,
    additives,
    allergens,
    baseUnitTypes,
    cart,
    cartTemplates,
    companies,
    currentUser,
    dashboard,
    deliveries,
    delivery,
    dictionaries,
    expiringPrices,
    importMappings,
    itemsImports,
    integrations,
    invitations,
    item,
    itemExtractReplacementGroups,
    itemReplacements,
    itemStatistics,
    itemPriceHistory,
    items,
    lookups,
    masterOrder,
    masterOrders,
    openPayments,
    order,
    orderTemplate,
    packagingTypes,
    pages,
    procurements,
    productGroups,
    replacementGroups,
    suppliers,
    supplierTenants,
    settings,
    taxRates,
    tenants,
    units,
    users,
  },
  state: {},
})
