<template>
  <div class="relative flex pl-2">
    <div class="relative">
      <button
        class="z-20 block overflow-hidden rounded-full focus:outline-none"
        :class="{ 'mr-2': !mobileView }"
        @click="toggleUserMenu"
        data-cy="user-menu-button"
      >
        <Avatar
          :user="currentUser.currentUser"
          :size="size"
          tooltip-placement="bottom"
        />
      </button>

      <transition :name="isXsMobileView ? 'scale-modal' : 'fade'">
        <div
          class="bg-default-bg"
          :class="{
            'absolute opacity-90 right-0 z-50 py-2 w-80 mt-4 border shadow-md rounded-10 user-menu border-silver':
              !isXsMobileView,
            'w-full h-full fixed inset-x-0 top-0 bottom-0 z-50 bg-default-bg outline-none mx-auto overflow-y-auto':
              isXsMobileView,
          }"
          v-if="isUserMenuShown"
        >
          <div class="outside" @click="toggleUserMenu"></div>

          <div>
            <div
              :class="{
                'flex justify-between font-medium text-sm mx-4 my-6':
                  isXsMobileView,
                hidden:
                  !isXsMobileView ||
                  isAppearanceMenuShown ||
                  isLanguageMenuShown,
              }"
            >
              <div>
                {{ t('profile.headers.profile') }}
              </div>
              <ButtonClose @click="isUserMenuShown = false" />
            </div>
            <div
              class="font-medium text-xs ml-4 mt-4 mb-2"
              :class="{
                hidden: isLanguageMenuShown,
              }"
            >
              {{ t('profile.headers.your_account') }}
            </div>

            <transition name="fade-modal">
              <div
                v-if="!isLanguageMenuShown"
                class="flex bg-alabaster rounded-10 min-h-18 cursor-pointer mx-4"
                @click.prevent="handleProfileShow"
              >
                <div
                  class="flex ml-2 rounded-full w-1/5 py-4"
                  :class="{
                    'items-start w-2/6': userRoleLength,
                  }"
                  data-cy="user-profile-link"
                >
                  <Avatar :user="currentUser.currentUser" size="md" />
                </div>

                <div
                  class="flex flex-col px-2 py-4"
                  :class="{
                    'items-start': userRoleLength,
                  }"
                >
                  <span class="font-medium text-sm">
                    {{ currentUser.currentUser.first_name }}
                    {{ currentUser.currentUser.last_name }}
                  </span>
                  <span class="text-xs">
                    {{
                      joinUserRoles
                        .map((role) => t(`roles.${role}`))
                        .join(', \n')
                    }}
                  </span>
                </div>
              </div>
            </transition>

            <transition name="slide-modal">
              <div v-if="!isLanguageMenuShown">
                <transition name="slide-modal">
                  <div class="px-4 mt-4">
                    <div class="mb-4" v-if="can('change', 'account')">
                      <div class="font-medium text-xs mb-2">
                        {{ t('profile.headers.managed_system') }}
                      </div>
                      <div class="mb-4" data-cy="profile-account-wrapper">
                        <SelectInput
                          :label="t('header.menu.account')"
                          :value="currentUser.currentUser.account.value"
                          track-by="id"
                          open-direction="bottom"
                          :options="currentUser.currentUser.account.options"
                          :allow-empty="true"
                          :preselect="true"
                          @open="getAvailableAccounts"
                          @select="handleAccountSelect"
                        />
                      </div>
                      <div class="flex font-medium text-sm items-center">
                        <span class="flex-grow">
                          {{ t('header.menu.demo_features') }}
                        </span>
                        <ToggleButton
                          class="-mr-5"
                          v-model="demoFeaturesToggle"
                          @input="handleDemoFeaturesToggle"
                          :disabled="isDemoPlan"
                        />
                      </div>
                    </div>
                  </div>
                </transition>
                <div class="flex flex-col mt-6 mx-5">
                  <a
                    class="flex flex-row font-medium items-center justify-left w-1/2 mb-0.5"
                    :class="{
                      'text-s1-blue-100': hoveredLogout,
                    }"
                    href=""
                    @click.prevent="deleteSession"
                    @mouseover="hoveredLogout = true"
                    @mouseleave="hoveredLogout = false"
                    data-cy="logout"
                  >
                    <ActionIcon
                      name="actions_logout"
                      class="mr-4"
                      :color="hoveredLogout ? 's1-blue-100' : 'medium-gray'"
                    />
                    {{ t('header.menu.logout') }}
                  </a>
                  <a
                    class="flex flex-row font-medium items-center justify-left w-1/2 my-7"
                    :class="{
                      'text-s1-blue-100': hoveredProfile,
                    }"
                    href=""
                    @click.prevent="handleProfileShow"
                    @mouseover="hoveredProfile = true"
                    @mouseleave="hoveredProfile = false"
                  >
                    <ActionIcon
                      name="actions_user"
                      class="mr-4"
                      :color="hoveredProfile ? 's1-blue-100' : 'medium-gray'"
                    />
                    {{ t('header.menu.profile') }}
                  </a>
                </div>
              </div>
            </transition>

            <div class="flex flex-col mb-2 mx-5">
              <a
                class="flex items-center justify-between w-full mb-4"
                :class="{
                  hidden: isLanguageMenuShown,
                  'text-s1-blue-100': hoveredLanguageMenu,
                }"
                href=""
                @click.prevent="handleLanguageChange"
                @mouseover="hoveredLanguageMenu = true"
                @mouseleave="hoveredLanguageMenu = false"
              >
                <span
                  v-if="currentUser.currentUser.preference.locale === 'de'"
                  class="flex items-center"
                >
                  <img :src="flagIcons[0]" alt="" />
                  <span class="ml-4 font-medium"
                    >{{ t('profile.headers.language') }}: Deutsch</span
                  >
                </span>
                <span
                  v-if="currentUser.currentUser.preference.locale === 'en'"
                  class="flex items-center"
                >
                  <img :src="flagIcons[1]" alt="" />
                  <span class="ml-4 font-medium"
                    >{{ t('profile.headers.language') }}: English</span
                  >
                </span>
                <span class="flex items-center">
                  <NavigationIcon
                    name="navigate_next"
                    size="xl"
                    :color="hoveredLanguageMenu ? 's1-blue-100' : 'medium-gray'"
                  />
                </span>
              </a>
            </div>
          </div>
          <div class="ml-4" v-if="isLanguageMenuShown">
            <div
              @click="handleLanguageChange"
              class="flex items-center font-medium my-4 cursor-pointer"
              :class="{ 'text-s1-blue-100': languageNavHovered }"
              @mouseover="languageNavHovered = true"
              @mouseleave="languageNavHovered = false"
            >
              <NavigationIcon
                :color="languageNavHovered ? 's1-blue-100' : 'medium-gray'"
                name="navigate_before"
                size="xl"
                class="mr-4"
              />{{ t('profile.headers.language') }}
            </div>
            <div
              @click="handleLanguageUpdate('en')"
              class="flex items-center font-medium my-7 ml-9 cursor-pointer hover:text-s1-blue-100"
            >
              <img :src="flagIcons[1]" class="mr-4" alt="" />
              {{ t('profile.headers.english') }}
            </div>
            <div
              @click="handleLanguageUpdate('de')"
              class="flex items-center font-medium my-4 ml-9 cursor-pointer hover:text-s1-blue-100"
            >
              <img :src="flagIcons[0]" class="mr-4" alt="" />
              {{ t('profile.headers.german') }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.outside {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}
</style>

<script setup>
import { computed, ref, defineProps, onMounted } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import ActionIcon from '@/components/shared/atoms/action-icon'
import Avatar from '@/components/shared/atoms/avatar'
import ButtonClose from '@/components/shared/atoms/button-close'
import NavigationIcon from '@/components/shared/atoms/navigation-icon'
import SelectInput from '@/components/shared/atoms/select-input'
import ToggleButton from '@/components/shared/atoms/toggle-button'
import useDetectScreenSize from '@/composables/detect-screen-size'
import useAbility from '@/composables/ability'
import { useActions, useGetters, useState } from '@/composables/store'
import { useRouter } from '@/composables/router'

const router = useRouter()
const { t, locale } = useI18n()
const { can } = useAbility()
const { isXsMobileView } = useDetectScreenSize()
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  mobileView: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['xxs', 'sm', 'md', 'full'].includes(value)
    },
  },
})

const { currentUser } = useState(['currentUser'])
const { updateTenantFeature } = useActions('tenants', ['updateTenantFeature'])
const { deleteSession, getAvailableAccounts, updatePreferences } = useActions(
  'currentUser',
  ['deleteSession', 'getAvailableAccounts', 'updatePreferences']
)
const { joinUserRoles, demoFeaturesEnabled, isDemoPlan } = useGetters(
  'currentUser',
  ['joinUserRoles', 'demoFeaturesEnabled', 'isDemoPlan']
)

const isUserMenuShown = ref(false)
const isAppearanceMenuShown = ref(false)
const isLanguageMenuShown = ref(false)
const flagIcons = [
  require('@/assets/images/svg/de-flag.svg'),
  require('@/assets/images/svg/br-flag.svg'),
]
const languageNavHovered = ref(false)
const hoveredLanguageMenu = ref(false)
const hoveredProfile = ref(false)
const hoveredLogout = ref(false)
const demoFeaturesToggle = ref(false)

onMounted(() => {
  demoFeaturesToggle.value = demoFeaturesEnabled.value
})

const userRoleLength = computed(() => {
  return joinUserRoles.value.join('').length >= 35
})

const handleLanguageChange = () => {
  isLanguageMenuShown.value = !isLanguageMenuShown.value
}
const toggleUserMenu = () => {
  isUserMenuShown.value = !isUserMenuShown.value && props.disabled
  isLanguageMenuShown.value = false
  isAppearanceMenuShown.value = false
}
const hideUserMenu = () => {
  isUserMenuShown.value = false
}
const handleLanguageUpdate = async (lang) => {
  await updatePreferences({ locale: lang })

  locale.value = lang
}
const handleAccountSelect = async (account) => {
  const company = account.companies[0] || null
  const cost_center = company?.cost_centers[0] || null

  await updatePreferences({
    tenant_id: account.id,
    company_id: company?.id || null,
    cost_center_id: cost_center?.id || null,
  })

  location.reload()
}
const handleProfileShow = () => {
  router.push('/profile')
  hideUserMenu()
}
const handleDemoFeaturesToggle = async (enabled) => {
  await updateTenantFeature({ key: 'demo', enabled })
  demoFeaturesToggle.value = demoFeaturesEnabled.value
}
</script>
