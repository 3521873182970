<template>
  <label
    class="inline-flex select-none items-center"
    :class="{
      'cursor-pointer': !disabled,
    }"
    :data-cy="dataCy"
  >
    <input
      type="checkbox"
      class="sr-only"
      @change="$emit('input', $event.target.checked)"
      :checked="$attrs.value"
      :disabled="disabled"
      v-bind="$attrs"
    />
    <span
      class="mr-3 flex h-6 w-12 items-center rounded-32 p-0.5"
      :class="{
        'bg-s1-gray-50': !$attrs.value,
        'bg-s1-blue-100': $attrs.value,
        'opacity-50': disabled,
      }"
    >
      <span
        class="shadow-toggle-button h-5 w-5 rounded-3xl bg-s1-main-100 duration-200 transform"
        :class="{
          'translate-x-6': $attrs.value,
        }"
        data-cy="switch-button"
      ></span>
    </span>
    <span class="flex items-center text-sm font-medium">
      <div class="ml-2 cursor-pointer" @click="$emit('input', !$attrs.value)">
        {{ label }}
      </div>
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
import { defineProps } from 'vue'

defineProps({
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataCy: {
    type: String,
    default: 'switch-button-wrapper',
  },
})
</script>
