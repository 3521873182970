import { computed } from 'vue'
import store from '@/store'

export const useStore = () => store

export const useState = (modules = []) => {
  const store = useStore()

  return modules.reduce(
    (states, key) => ({
      ...states,
      [key]: computed(() => store.state[key]),
    }),
    {}
  )
}

export const useGetters = (module, keys = []) => {
  const store = useStore()

  return keys.reduce(
    (getters, key) => ({
      ...getters,
      [key]: computed(() => store.getters[`${module}/${key}`]),
    }),
    {}
  )
}

export const useActions = (module, keys = []) => {
  const store = useStore()

  return keys.reduce(
    (actions, key) => ({
      ...actions,
      [key]: function () {
        return store.dispatch.apply(store, [`${module}/${key}`, ...arguments])
      },
    }),
    {}
  )
}
