import { getCurrentInstance } from 'vue'

export const useRouter = () => {
  const instance = getCurrentInstance()

  return instance.proxy.$router
}

export const useRoute = () => {
  const instance = getCurrentInstance()

  return instance.proxy.$route
}
